import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ConnectedPosition, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-custom-dropdown',
  template: ` <ng-template cdk-portal="">
    <ng-content></ng-content>
  </ng-template>`
})
export class CustomDropdownComponent implements OnInit, OnDestroy {
  constructor(
    protected overlay: Overlay,
    private cdr: ChangeDetectorRef
  ) {}

  protected overlayRef: OverlayRef;
  protected showing: boolean;

  private unsubscribe$ = new Subject<void>();
  private resizeObserver: ResizeObserver;

  @Input() reference: HTMLElement;
  @Input() flexibleWidth = false;
  @Input() withOutsidePointerEvents = false;
  @Input() withComposedPath = false;
  @Input() withBackdrop = false;
  @Input() position: 'start' | 'center' | 'end' = 'start';
  @Input() customPositions: ConnectedPosition[] | null = null;

  @ViewChild(CdkPortal) contentTemplate: CdkPortal;

  @Output() toggled = new EventEmitter<boolean>();

  get isShown(): boolean {
    return this.showing;
  }

  ngOnInit(): void {
    if (this.flexibleWidth) {
      this.setUpFlexibleWidth();
    }
  }

  toggle(): void {
    if (this.showing) {
      this.hide();
    } else {
      this.show();
    }
  }

  show(): void {
    if (!this.showing) {
      this.overlayRef = this.overlay.create(this.getOverlayConfig());

      this.overlayRef.attach(this.contentTemplate);
      this.overlayRef
        .backdropClick()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => this.hide());

      if (this.withOutsidePointerEvents) {
        this.overlayRef
          .outsidePointerEvents()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((event: MouseEvent) => this.handleOutsidePointerEvents(event));
      }

      this.toggled.emit(true);

      this.showing = true;
      this.cdr.markForCheck();
    }
  }

  hide(): void {
    if (this.overlayRef && this.showing) {
      this.overlayRef.detach();
      this.toggled.emit(false);

      this.showing = false;
      this.cdr.markForCheck();
    }
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.reference)
      .withPush(false)
      .withPositions(this.getOverlayPositionConfig())
      .setOrigin(this.reference);
    const scrollStrategy = this.overlay.scrollStrategies.reposition({
      scrollThrottle: 0,
      autoClose: false
    });

    return new OverlayConfig({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: this.withBackdrop,
      backdropClass: 'cdk-overlay-transparent-backdrop'
    });
  }

  private getOverlayPositionConfig(): ConnectedPosition[] {
    if (this.customPositions) {
      return this.customPositions;
    }

    return [
      {
        originX: this.position,
        originY: 'bottom',
        overlayX: this.position,
        overlayY: 'top'
      },
      {
        originX: this.position,
        originY: 'top',
        overlayX: this.position,
        overlayY: 'bottom'
      }
    ];
  }

  private handleOutsidePointerEvents(event: MouseEvent): void {
    if (this.withComposedPath) {
      if (!event.composedPath()?.includes(this.reference)) {
        this.hide();
      }
    } else {
      this.hide();
    }
  }

  private setUpFlexibleWidth(): void {
    this.resizeObserver = new ResizeObserver(() => this.updateFlexibleWidth());
    this.resizeObserver.observe(this.reference);
  }

  updateFlexibleWidth(): void {
    if (!this.flexibleWidth) return;
    if (!this.reference) return;

    this.overlayRef?.updateSize({ width: this.reference.clientWidth });
  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
