import {
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Injector,
  Input,
  OnDestroy,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  Candidate,
  CleverStaffProfileDupclication,
  ContactInfo,
  ContactInfoItem,
  ContactUserDto,
  CreateCustomElementInfo,
  Integration,
  LinkDto,
  SimpleCandidate
} from '../../../../shared/models';
import { SafeResourceUrl } from '@angular/platform-browser';
import {
  CandidateContactsService,
  CustomElementsService,
  DocumentService,
  LoggerService,
  ProfileService,
  UserService
} from '../../../../shared/services';
import { DownloadProfileCVContentComponent } from './download-profile-cv-content';
import { CustomElementsEnum } from '../../../../shared/enums';
import { NgElement, WithProperties } from '@angular/elements';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  Observable,
  of,
  Subject,
  throwError
} from 'rxjs';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { FontsInfo } from '../../../../shared/models/candidate/candidateCVGenerator';
import { CandidatesCVGeneratorService } from '../../services';
import { CandidateProfileHandlerService } from '../../services/candidates/candidate-profile-handler.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LimitationService } from '../../services/limitation.service';
import { CustomDropdownComponent } from 'src/app/modules/platform/components';
import { ExportService } from 'src/app/shared/services/requests/export.service';
import { CleverStaffExportSettingsAPIPayload } from 'src/app/shared/models/export';
import { MatDialog } from '@angular/material/dialog';
import { CleverStaffExportModalComponent } from '../clever-staff-export-modal';
import { IntegrationService } from 'src/app/shared/services/requests/integration.service';

type CvComponentType = NgElement &
  WithProperties<{
    profile: Candidate;
    image: SafeResourceUrl | string;
    contacts: ReadonlyMap<string, ContactInfoItem>;
    links: LinkDto[];
    startDownloadTime: number;
    fontsInfo: FontsInfo;
    full?: boolean;
  }>;

interface DownloadInfo {
  isInProgress?: boolean;
}

@Component({
  selector: 'app-download-profile-c-v',
  templateUrl: './download-profile-c-v.component.html',
  styleUrls: ['./download-profile-c-v.component.scss']
})
export class DownloadProfileCVComponent implements OnDestroy {
  constructor(
    private viewContainerRef: ViewContainerRef,
    private customElementsService: CustomElementsService,
    private injector: Injector,
    private applicationRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private documentService: DocumentService,
    private contactService: CandidateContactsService,
    private profileService: ProfileService,
    private userService: UserService,
    private exportService: ExportService,
    private integrationService: IntegrationService,
    private logger: LoggerService,
    private candidatesCVGenerator: CandidatesCVGeneratorService,
    private candidateHandler: CandidateProfileHandlerService,
    private limitationService: LimitationService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {}

  @Input() profile: Candidate | SimpleCandidate;
  @Input() image: SafeResourceUrl | string;
  @Input() isFullProfile = false;
  @Input() forSearchPage = false;
  @Input() limitAccess = false;

  @ViewChild('exportMenuDropdown') exportMenuDropdown: CustomDropdownComponent;

  private cvComponent: CvComponentType;
  private unsubscribe$ = new Subject<void>();
  private downloadInProgress = false;
  cleverStaffModalIsPending = false;
  private downloadInfoSubject = new BehaviorSubject<DownloadInfo>({
    isInProgress: false
  });

  private fullProfile: Candidate;
  private contactsInfoMap: ReadonlyMap<string, ContactInfoItem>;
  private links: LinkDto[];
  private startDownloadTime: number;

  private readonly linksLimit = 20;

  downloadInfo$: Observable<DownloadInfo> = this.downloadInfoSubject.asObservable();

  startExport(): void {
    if (this.downloadInProgress || this.cleverStaffModalIsPending) return;

    const user = this.userService.getUser();
    const cleverStaffIntegration = user?.integrations.find(
      (integration: Integration) => integration.type === 'cleverstaff'
    );

    if (!cleverStaffIntegration) {
      this.download();
    } else {
      this.exportMenuDropdown.show();
    }
  }

  openCleverStaffExportModal(): void {
    this.cleverStaffModalIsPending = true;
    this.exportMenuDropdown.hide();

    const { docId } = this.profile as Candidate;
    const $profileDublications = this.integrationService
      .getProfileCleverStaffByDocId(docId)
      .pipe(catchError(() => of(null)));
    const $settings = this.exportService.getCleverStaffSettings();

    combineLatest([$settings, $profileDublications])
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((error) => {
          this.cleverStaffModalIsPending = false;
          return throwError(error);
        })
      )
      .subscribe(
        ([settings, duplications]: [
          CleverStaffExportSettingsAPIPayload,
          CleverStaffProfileDupclication[]
        ]) => {
          const { docId } = this.profile as Candidate;
          this.cleverStaffModalIsPending = false;

          this.dialog
            .open(CleverStaffExportModalComponent, {
              viewContainerRef: this.viewContainerRef,
              data: { settings: settings, userDocId: docId, duplications }
            })
            .afterClosed()
            .subscribe();

          this.cdr.markForCheck();
        }
      );
  }

  download(): void {
    this.exportMenuDropdown.hide();

    if (this.profile && !this.limitAccess) {
      this.toggleDownloadInProgress(true);

      this.startDownload();
    }
  }

  downloadFinished(): void {
    this.toggleDownloadInProgress(false);

    this.clearCvComponent();
  }

  private toggleDownloadInProgress(isInProgress: boolean): void {
    this.downloadInProgress = isInProgress;

    this.downloadInfoSubject.next({ isInProgress });
  }

  // **************************************** Start download: *********************************************
  private startDownload(): void {
    if (this.profile) {
      this.startDownloadTime = new Date().getTime();

      this.logger.log('=========> Download CV started', false);
      this.setDataToCvComponent();
    } else {
      this.downloadFinished();
    }
  }

  // Get data handlers:
  private setDataToCvComponent(): void {
    const profileId: string = this.profile.profileIds[0];
    const docId: string = (this.profile as Candidate).docId;

    combineLatest([this.getCandidate$(profileId), this.getProfileLinks$(docId)])
      .pipe(
        mergeMap(([profile, links = []]: [Candidate, LinkDto[]]) => {
          this.fullProfile = profile ? profile : (this.profile as Candidate);
          this.links = this.getLimitedLinks(links);

          return this.getProfileContacts$(profileId);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (contactsInfo: ReadonlyMap<string, ContactInfoItem>) => {
          this.contactsInfoMap = contactsInfo;
          this.downloadCV();
        },
        () => {
          this.downloadFinished();
        }
      );
  }

  private getLimitedLinks(links: LinkDto[]): LinkDto[] {
    return links?.length > this.linksLimit ? links.slice(0, this.linksLimit) : links;
  }

  private getCandidate$(profileId: string): Observable<Candidate> {
    return this.isFullProfile
      ? of(null)
      : this.profileService
          .getCandidate(profileId)
          .pipe(
            catchError((error: HttpErrorResponse) =>
              this.limitationService.onRequestLimitCatchError(error, true, !this.forSearchPage)
            )
          );
  }

  private getProfileContacts$(profileId: string): Observable<ReadonlyMap<string, ContactInfoItem>> {
    return this.profileService.openProfileContacts(profileId).pipe(
      map((contacts: ContactUserDto[]) => {
        const info: ContactInfo = this.contactService.getContactsInfo(contacts);

        this.candidateHandler.contactsOpened(contacts);

        return this.contactService.getContactsInfoMap(info);
      }),
      catchError((error: HttpErrorResponse) =>
        this.limitationService.onRequestLimitCatchError(error, true, !this.forSearchPage)
      )
    );
  }

  private getProfileLinks$(docId: string): Observable<LinkDto[]> {
    return this.profileService
      .getProfileLinks(docId)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.limitationService.onRequestLimitCatchError(error, true, !this.forSearchPage)
        )
      );
  }

  // Custom component handlers:
  private downloadCV(): void {
    this.createCustomElement();

    this.cvComponent = this.documentService.createElement(
      CustomElementsEnum.downloadProfileCV
    ) as CvComponentType;

    if (this.cvComponent) {
      this.cvComponent.classList.add('hidden-wrapper');
      this.setDataToCVComponent(this.cvComponent, this.candidatesCVGenerator.fonts);
      this.appendCvComponent(this.cvComponent);
    }
  }

  private createCustomElement(): void {
    const info: CreateCustomElementInfo = {
      info: {
        name: CustomElementsEnum.downloadProfileCV,
        component: DownloadProfileCVContentComponent
      },
      injector: this.injector,
      applicationRef: this.applicationRef,
      componentFactoryResolver: this.componentFactoryResolver
    };

    this.customElementsService.createCustomElement(info);
  }

  private setDataToCVComponent(cvComponent: CvComponentType, fontsInfo: FontsInfo = null): void {
    if (cvComponent) {
      cvComponent.image = this.image;
      cvComponent.contacts = this.contactsInfoMap;
      cvComponent.links = this.links;
      cvComponent.full = this.isFullProfile;
      cvComponent.startDownloadTime = this.startDownloadTime;
      cvComponent.fontsInfo = fontsInfo;
      cvComponent.profile = this.fullProfile;

      fromEvent(cvComponent, 'downloadFinished')
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((event: CustomEvent) => {
          this.logger.log(
            `===> download finished ${(new Date().getTime() - this.startDownloadTime) / 1000}`,
            false
          );
          this.setFontsGloballyOnCustomEvent(event);
          this.downloadFinished();
        });
    }
  }

  private setFontsGloballyOnCustomEvent(event: CustomEvent): void {
    if (event?.detail) {
      const fontsInfo: FontsInfo = event.detail;

      if (fontsInfo?.fontDataList && fontsInfo?.htmlFontFaces) {
        this.candidatesCVGenerator.fonts = event.detail;
      }
    }
  }

  private appendCvComponent(cvComponent: HTMLElement): void {
    const body: HTMLElement = this.documentService.querySelector('body');

    if (body) {
      body.appendChild(cvComponent);
    }
  }
  // *******************************************************************************************************

  // ******************************************* Finish download: ******************************************
  private clearCvComponent(): void {
    if (this.cvComponent) {
      (this.cvComponent as HTMLElement).remove();

      this.cvComponent = null;
    }
  }
  // ********************************************************************************************************

  // Handle destroy host component:
  ngOnDestroy(): void {
    this.clearCvComponent();

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
