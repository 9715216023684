import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CleverStaffProfileDupclication, ModalInfo } from '../../../../shared/models';
import { SystemPages } from '../../../../shared/enums/routerPaths.enum';
import { SelectionService } from 'src/app/modules/system-shared/services/selection.service';
import { Subject, of, timer } from 'rxjs';
import { catchError, delay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ExportService } from 'src/app/shared/services/requests/export.service';
import {
  CleverStaffProfileExportSettings,
  CSVExportRecord,
  CSVExportState,
  ExportProfilesRequestPayload
} from 'src/app/shared/models/export';

@Component({
  selector: 'app-clever-staff-export-modal',
  templateUrl: './clever-staff-export-modal.component.html',
  styleUrls: ['./clever-staff-export-modal.component.scss']
})
export class CleverStaffExportModalComponent implements OnInit, OnDestroy {
  constructor(
    private dialogRef: MatDialogRef<CleverStaffExportModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      settings: CleverStaffProfileExportSettings;
      exportRequestQuery: string;
      userDocId?: string;
      folderId?: string;
      activeFolderStatuses?: string[];
      duplications: CleverStaffProfileDupclication[] | null;
    },
    private exportService: ExportService,
    @Optional() private selectionService: SelectionService,
    private cdr: ChangeDetectorRef
  ) {}

  readonly systemPagesEnum = SystemPages;

  state: 'settings' | 'waiting' | 'success' | 'error' = 'settings';
  infoText: string = '';
  showContactSettings = false;
  withContacts = false;
  progressPercentage = 0;
  dublications: CleverStaffProfileDupclication[] | null = null;
  $unsubscribe = new Subject<void>();
  $stopProgressImitation = new Subject<void>();

  info: ModalInfo = {
    type: 'simple-modal',
    closeModalBtnId: 'close-plan-limitation-m',
    header: {
      title: 'EXPORT.CLEVER_STAFF_EXPORT_MODAL.TITLE',
      text: 'EXPORT.CLEVER_STAFF_EXPORT_MODAL.SUBTITLE'
    },
    actions: {
      cancel: {
        id: 'm-cancel-export-btn'
      },
      submit: {
        id: 'm-confirm-export-btn',
        extraClass: 'bright',
        type: 'update',
        text: 'Export'
      }
    }
  };

  @HostListener('keydown.enter') onEnterPressed(): void {
    if (this.state === 'settings' && this.info.actions.submit.disabled === false) {
      this.onExportSubmited();
    }
  }

  ngOnInit(): void {
    this.withContacts = this.data.settings.contacts;
    this.showContactSettings = this.withContacts;

    if (!this.withContacts && !this.data.duplications) {
      this.onExportSubmited();
    }

    this.dublications = this.getpreprocessedDublications();
  }

  toggleContactSetting(): void {
    this.withContacts = !this.withContacts;
  }

  onExportSubmited(): void {
    this.info.header = {};
    this.info.actions = null;
    this.state = 'waiting';
    this.infoText = 'EXPORT.CLEVER_STAFF_EXPORT_MODAL.INFO.IN_PROGRESS';

    const searchQuery = this.data.exportRequestQuery || '';
    const payload = this.getRequestPaylod();

    this.imitateProgress();

    this.exportService
      .createCleverStaffProfileExport(searchQuery, payload)
      .pipe(
        takeUntil(this.$unsubscribe),
        catchError((e) => of(null))
      )
      .subscribe((record: CSVExportRecord) => {
        if (!record?.status) {
          this.handleExportFailure();
          return;
        }

        const { status } = record;
        this.handleResponce({ status }, record);
      });
  }

  getRequestPaylod(): ExportProfilesRequestPayload {
    // Single profile export
    if (this.data.userDocId) {
      return {
        allProfiles: false,
        withContacts: this.withContacts,
        ids: [this.data.userDocId]
      };
    }

    const selection = this.selectionService.getSelection();

    const payload: ExportProfilesRequestPayload = {
      allProfiles: selection.allProfiles,
      withContacts: this.withContacts
    };
    if (selection.allProfiles && this.data.folderId) {
      payload.folderId = this.data.folderId;
      payload.statusIds = this.data.activeFolderStatuses || [];
    }
    if (selection.range) {
      payload.fromRange = selection.range.from;
      payload.toRange = selection.range.to;
    }
    if (selection.docIds) {
      payload.ids = Array.from(selection.docIds.values());
    }

    return payload;
  }

  handleResponce(exportState: CSVExportState, record: CSVExportRecord): void {
    if (!exportState.status) {
      this.handleExportFailure();
      return;
    }

    const { status } = exportState;

    if (typeof exportState?.percent === 'number') {
      this.$stopProgressImitation.next();
      this.updateProgressPercentage(exportState);
    }

    if (status === 'PENDING' || status === 'IN_PROGRESS') {
      this.waitUntilExportIsResolved(record);
      return;
    }

    if (status === 'SUCCEEDED') {
      this.handleExportSuccess(record);
      return;
    }

    this.handleExportFailure();
  }

  waitUntilExportIsResolved(record: CSVExportRecord): void {
    timer(1000)
      .pipe(
        take(1),
        takeUntil(this.$unsubscribe),
        switchMap(() => this.exportService.getExportState(record.id)),
        tap((exportState: CSVExportState) => {
          this.handleResponce(exportState, record);
        }),
        catchError(() => {
          this.handleExportFailure();
          return of(null);
        })
      )
      .subscribe();
  }

  handleExportSuccess(_: CSVExportRecord): void {
    this.state = 'success';
    this.infoText = 'EXPORT.CLEVER_STAFF_EXPORT_MODAL.INFO.SUCCESSFULLY_EXPORTED';
    this.cdr.detectChanges();
  }

  handleExportFailure(): void {
    this.$stopProgressImitation.next();
    this.state = 'error';
    this.infoText = 'EXPORT.EXPORT_MODAL.INFO.EXPORT_FAILED';
    this.cdr.detectChanges();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  updateProgressPercentage(exportState: CSVExportState): void {
    if (!exportState || typeof exportState.percent !== 'number') return;

    this.progressPercentage = exportState.percent;
    this.cdr.detectChanges();
  }

  /** If export is in PENDING state we will increase an percent counter by 1 every second
   * until 10 percent is reached or export status is changed.
   */
  imitateProgress(): void {
    of(null)
      .pipe(delay(1000), takeUntil(this.$unsubscribe), takeUntil(this.$stopProgressImitation))
      .subscribe(() => {
        if (this.progressPercentage >= 10) {
          return;
        }

        this.progressPercentage++;
        this.cdr.markForCheck();
        this.imitateProgress();
      });
  }

  private getpreprocessedDublications(): CleverStaffProfileDupclication[] | null {
    if (!this.data.duplications) return null;

    return this.data.duplications.map((dublication: CleverStaffProfileDupclication) => {
      return {
        ...dublication,
        date: new Date(dublication.dm)
      };
    });
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$stopProgressImitation.next();
    this.$unsubscribe.complete();
    this.$stopProgressImitation.complete();
  }
}
